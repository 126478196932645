<template>
	<div class="app-container">		
		<div class="filter-container mt-3">
			<el-date-picker v-model="searchData.date_range" type="daterange" align="right" unlink-panels :range-separator="$t('general.to')" :start-placeholder="$t('general.date_start')" :end-placeholder="$t('general.date_end')" :picker-options="pickerOptions" class="filter-item" value-format="yyyy-MM-dd" :disabled="loading" @change="saveData()"></el-date-picker>

			<el-select v-model="searchData.brand_id" class="filter-item" filterable :placeholder="$t('admin_general.table_brand')" clearable @change="saveData()">
				<el-option v-for="(item,index) in brandList" :key="index" :label="item.name" :value="item.id" :disabled="loading"></el-option>
			</el-select>
			
			<el-select v-model="searchData.models_id" class="filter-item" filterable :placeholder="$t('admin_general.table_model')" clearable @change="saveData()">
				<el-option v-for="(item,index) in modelsList" :key="index" :label="item.name" :value="item.id" :disabled="loading"></el-option>
			</el-select>
			
			<el-select v-model="searchData.size_id" class="filter-item" filterable :placeholder="$t('admin_general.table_size')" clearable @change="saveData()">
				<el-option v-for="(item,index) in sizeList" :key="index" :label="item.name" :value="item.id" :disabled="loading"></el-option>
			</el-select>

			<el-select v-model="searchData.color_id" class="filter-item" filterable :placeholder="$t('admin_general.table_color')" clearable @change="saveData()">
				<el-option v-for="(item,index) in colorList" :key="index" :label="item.name" :value="item.id" :disabled="loading"></el-option>
			</el-select>
			
			<el-button class="filter-item" type="primary" icon="el-icon-search" @click="initial()" :loading="buttonloading">{{$t('button.search')}}</el-button>
			
			<el-button class="filter-item float-right" type="primary" :loading="buttonloading" @click="addRow()" v-if="permissionList.includes(permission.add)" icon="el-icon-plus">{{$t('button.add')}}</el-button>
		</div>
		
		<el-table show-summary :summary-method="getSummaries" :data="tableData" v-loading="loading" style="width: 100%;" @expand-change="expandChange" @row-click="expandRow" class="mt-3" ref="tableTest" border fit highlight-current-row>
			<template v-for="title in ajaxTitles">
				<el-table-column v-if="title.prop == 'img_url'" :prop="title.prop" :label="title.label" :key="title.prop" :min-width="title.width">
					<template slot="header">
						<p class="search-label">{{title.label}}</p>
						<b-form-input :placeholder="title.label" @keyup.enter.native="initial" v-model="searchData[title.prop]" disabled></b-form-input>
					</template>
					
					<template slot-scope="scope" >
						<el-image :src="scope.row.img_url" class="img-thumbnail"></el-image>
					</template>
				</el-table-column>
				
				<el-table-column v-else :prop="title.prop" :label="title.label" :key="title.prop" :min-width="title.width">
					<template slot="header">
						<p class="search-label">{{title.label}}</p>
						<b-form-input :placeholder="title.label" @keyup.enter.native="initial" v-model="searchData[title.prop]" @change="saveData()"></b-form-input>
					</template>
					
					<template slot-scope="scope">
						<span v-if="title.prop == 'retail_price'"><b class="text-green">{{scope.row[title.prop]}}</b></span>
						<span v-else-if="title.prop == 'cost'"><b class="text-primary">{{scope.row[title.prop]}}</b></span>
						<span v-else-if="title.prop == 'member_price'"><b class="text-green">{{scope.row[title.prop]}}</b></span>
						<span v-else-if="title.prop == 'commission_value'"><b class="text-green">{{scope.row[title.prop]}}</b></span>
						<span v-else>{{scope.row[title.prop]}}</span>
					</template>
				</el-table-column>
			</template>
            <el-table-column type="expand">
                <template slot-scope="scope">
                    <el-button type="primary" :loading="buttonloading" @click="editRow(scope.row.id)" v-if="permissionList.includes(permission.edit)" icon="el-icon-edit">{{$t('button.edit')}}</el-button>
                    <el-button type="danger" :loading="buttonloading" @click="deleteRow(scope.row.id)" v-if="permissionList.includes(permission.delete)" icon="el-icon-delete">{{$t('button.delete')}}</el-button>
                </template>
            </el-table-column>
        </el-table>

		<pagination v-show="total > 0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="pagination"/>
		
		<el-tooltip placement="top" :content="$t('general.back_to_top')">
			<backtotop :visibility-height="300" :back-position="50" transition-name="fade" />
		</el-tooltip>
	</div>
</template>

<script>
import {getLocalStorage} from '@/system/store/localstorage';
import {postMethod} from '@/system/model/post/post';
import {getDAES, getEAES} from '@/system/library/security';
import pagination from '@/components/pagination';
import backtotop from '@/components/backtotop';

let searchForm = {
	pagination: 1,
	limit: 10,
	id: '',
	code:'',
	date_range:'',
	created_at:'',
	brand_id:'',
	models_id:'',
	color_id:'',
	size_id:'',
	sort:'',
	name:'',
	filter:'',
	brand:'',
	models:'',
	size:'',
	color:'',
	cost:'',
	retail_price:'',
	member_price:'',
	commission_value:'',
	display_discount_rate:'',
	status:''
}

export default{
	components: { pagination, backtotop },
	inject:['preloader'],
	data(){
		return {
			loading: true,
			buttonloading: false,
			tableData: [],
			total: 0,
			errors: [],
			openImageDialog: false,
			submitForm: {
				id:'',
				security:''
			},
			postData: {
				data: '',
				language: ''
			},
			searchData: Object.assign({}, searchForm),
			listQuery: {
				page: 1,
				limit: 10
			},
			ajaxTitles:[{
                prop: "id",
                label: this.$t('admin_general.table_id'),
                width:'50'
            },{
                prop: "img_url",
                label: this.$t('admin_general.table_image'),
                width:'80'
			},{
                prop: "created_at",
                label: this.$t('admin_general.table_created_at'),
                width:'100'
			},{
                prop: "code",
                label: this.$t('admin_general.table_code'),
                width:'100'
			},{
                prop: "name",
                label: this.$t('admin_general.table_name'),
                width:'100'
			},{
                prop: "filter",
                label: this.$t('admin_general.table_filter'),
                width:'100'
			},{
                prop: "brand",
                label: this.$t('admin_general.table_brand'),
                width:'100'
			},{
                prop: "models",
                label: this.$t('admin_general.table_model'),
                width:'100'
			},{
                prop: "size",
                label: this.$t('admin_general.table_size'),
                width:'100'
			},{
                prop: "color",
                label: this.$t('admin_general.table_color'),
                width:'100'
			},{
                prop: "cost",
                label: this.$t('admin_general.table_cost'),
                width:'100'
			},{
                prop: "retail_price",
                label: this.$t('admin_general.table_retail_price'),
                width:'100'
			},{
                prop: "member_price",
                label: this.$t('admin_general.table_member_price'),
                width:'100'
			},{
                prop: "commission_value",
                label: this.$t('admin_general.table_commission_value'),
                width:'100'
			},{
                prop: "display_discount_rate",
                label: this.$t('admin_general.table_discount_percentage'),
                width:'100'
			},{
                prop: "sort",
                label: this.$t('admin_general.table_sort'),
                width:'60'
			},{
                prop: "status",
                label: this.$t('admin_general.table_status'),
                width:'60'
			}]
			,permission:{
                index:'HMHLIS',
                ajaxTable:'ZKM5WA',
                add:'GH2BJ4',
                edit:'6ELC82',
                delete:'7XFUBN',
                DBdelete:'87M848'
            },
			brandList:[],
			sizeList:[],
			colorList:[],
			modelsList:[],
			permissionList:[],
			languageList:[],
			pickerOptions: {
				shortcuts: [{
					text: this.$t('general.recent_week'),
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				},{
					text: this.$t('general.recent_one_month'),
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				},{
					text: this.$t('general.recent_three_month'),
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
            }
		}
	}, methods: {
		getInitial(){
			if(this.permissionList.includes(this.permission.index)){
				this.loading = true;
				this.buttonloading = true;
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.searchData));
				var self = this;
				var result = postMethod('catalog/product',this.postData);
				result.then(function(value){
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						self.brandList = data.brandList;
						self.sizeList = data.sizeList;
						self.colorList = data.colorList;
						self.modelsList = data.modelsList;
						self.initial();
					}
				});
			}
        },
		initial(){
			if(this.permissionList.includes(this.permission.ajaxTable)){
				this.loading = true;
				this.buttonloading = true;
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.searchData));
				var self = this;
				var result = postMethod('catalog/product/ajaxTable',this.postData);
				result.then(function(value){
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						self.tableData = data.datatable.data;
						self.total = parseInt(data.datatable.total_number);
						self.listQuery.page = parseInt(data.datatable.current_pagination);
						self.listQuery.limit = parseInt(data.datatable.limit);
						self.buttonloading = false;
						self.loading = false;
					}
				});
			}
		},deleteRow(id){
			if(this.permissionList.includes(this.permission.DBdelete)){
				this.buttonloading = true;
				var text = '';
				var self = this;
				
				this.$prompt(this.$t('msg.msg_delete'), this.$t('msg.prompt'), {
					confirmButtonText: this.$t('button.confirm'),
					cancelButtonText: this.$t('button.cancel'),
					inputPlaceholder: this.$t('msg.msg_security_password'),
					inputType: 'password'
				}).then(({ value }) => {
					this.submitForm.id = id;
					this.submitForm.security = value;
					this.postData.data = '';
					this.postData.data = getEAES(JSON.stringify(this.submitForm));
					var result = postMethod('catalog/product/DBdelete',this.postData);
					result.then(function(value){
					var data = JSON.parse(getDAES(value.data));
					if(value.valid){
						self.$message({
						type: 'success',
						message: data.returnMsg
						});
						
						self.initial();
					}else{
						self.errors = data.returnMsg;
						
						self.errors.forEach(function (value){
						text+= value+"<br/>";
						});
						
						self.$method.popupErrorMessage(self, text);
					}
					
					});
					
					this.buttonloading = false;

				}).catch(() => {
					this.buttonloading = false;          
				});
			}
		},pagination(){
			this.searchData.pagination = this.listQuery.page;
			this.searchData.limit = this.listQuery.limit;
			this.initial();
		},expandRow(row){
            this.$refs.tableTest.toggleRowExpansion(row);
        },expandChange(row,expandedRows){
            if(expandedRows.length>1){
                this.$refs.tableTest.toggleRowExpansion(expandedRows[0]);
            }
        },addRow(){
			if(this.permissionList.includes(this.permission.add)){
				this.$router.push({ name: 'menu.catalog_product_add'});
			}
		},editRow(data){
			if(this.permissionList.includes(this.permission.edit)){
				this.$router.push({ name: 'menu.catalog_product_edit', params: {id: data}});
			}
		},getSummaries(param) {
			const { columns, data } = param;
			const sums = [];
			columns.forEach((column, index) => {
				if (index === 0) {
					sums[index] = this.$t('general.grand_total');
					return;
				}
				
				if(index == 9 || index == 10 || index == 11){
					const values = data.map(item => Number(item[column.property]));

					if (!values.every(value => isNaN(value))) {
						sums[index] = values.reduce((prev, curr) => {
							const value = Number(curr);
							if (!isNaN(value)) {
								return prev + curr;
							} else {
								return prev;
							}
						}, 0);
						
						sums[index] = parseFloat(sums[index]).toFixed(2);
					} else {
						sums[index] = '';
					}
				}else{
					return;
				}
			});

			return sums;
		},saveData(){
			searchForm = this.searchData;
		}
	}, created: function(){
		var currentLang = (getLocalStorage('currentLang')) ? getLocalStorage('currentLang') : 'en';
		this.postData.language = currentLang;
		this.permissionList = JSON.parse(getLocalStorage('permissionList'));
		this.languageList = JSON.parse(getLocalStorage('languageList'));
		
		this.getInitial();
	}
}
</script>